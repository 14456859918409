export const breakpoints = {
  sm: '26',
  md: '44',
  lg: '75',
  xl: '90',
}

const mediaMin = size => `@media screen and (min-width: ${size}rem)`;
export const mediaMax = size => `@media screen and (max-width: ${size}rem)`;
export const mediaMinMax = (min, max) => `@media screen and (min-width: ${min}rem) and (max-width: ${max}rem)`;

export default mediaMin;
