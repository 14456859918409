import React from 'react';
import { NavContext } from '../../context/NavManager';
import { Match } from '@reach/router';

import * as S from './styles';
import BurgerButton from '../burgerButton';

const NavLink = (props) => (
  <Match path={props.id}>
    {({match}) => (
      <S.Nav.Link to={props.id} open={props.state.isNavOpen} active={match ? 1 : 0} onClick={() => props.toggleNav()}>
        {props.text}
      </S.Nav.Link>
    )}
  </Match>
);

const Nav = ({ navItems }) => {
  return (
    <NavContext.Consumer>
      {context => (
        <S.Nav isActive={context.state.isNavOpen}>
          <S.Nav.Logo isActive={context.state.isNavOpen}>
            <BurgerButton action={context.toggleNav} isActive={context.state.isNavOpen} color='primary' />

            <S.Nav.Title>
              <p>Kinga</p>
              <p>Sieminiak</p>
              <p>Poftfolio</p>
            </S.Nav.Title>
          </S.Nav.Logo>

          <S.Nav.Container>
            <S.Nav.List isActive={context.state.isNavOpen}>
              {navItems.map(item => (
                <li key={item.id}>
                  <NavLink id={item.id} text={item.text} {...context}/>
                </li>
              ))}
            </S.Nav.List>
          </S.Nav.Container>
        </S.Nav>
      )}
    </NavContext.Consumer>
  );
}

export default Nav;
