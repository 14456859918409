import styled from 'styled-components';
import { Link } from '@reach/router';

export const Button = styled(Link)(({ theme }) => ({
    display: 'inline-block',
    height: '50px',
    lineHeight: '50px',
    overflow: 'hidden',
    padding: '0 2rem',
    fontSize: '.85rem',
    fontWeight: 600,
    letterSpacing: '1px',
    color: theme.white,
    backgroundColor: theme.secondary,
    textDecoration: 'none',
    border: `1px solid ${theme.white}`,
    transition: 'opacity .3s ease-in-out',
    cursor: 'pointer',
    outlineColor: theme.secondary,

    '&:active': {
      transform: 'translateY(3px)'
    }
}));

export const ALink = styled.a(
  {
    marginRight: '1rem',
    fontSize: '.9rem',
    fontWeight: '600',
    color: 'rgba(0, 0, 0, .35)',
    transition: 'color .3s ease-in-out',
    textDecoration: 'none',
    marginTop: '1rem',
    cursor: 'pointer',

    '&:active': {
      transform: 'translateY(3px)'
    },
  },

  ({ theme }) => ({ '&:hover': { color: theme.secondary } })
);

export const IconLink = styled.a(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: '.5rem',
  backgroundColor: 'transparent',
  border: 'none',
  color: theme.secondary,
  textTransform: 'uppercase',
  fontSize: '.7rem',
  fontWeight: 600,
  letterSpacing: '1px',
  transition: 'opacity .3s ease-in-out',
  outlineColor: theme.secondary,
  textDecoration: 'none',

  '& > svg': {
    width: '1rem',
    marginLeft: '.5rem',
  },

  '& > span': {
    paddingTop: '.1rem'
  },

  '&:hover': {
    opacity: .6
  }
}));

export const IconButton = styled.button(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  padding: '.5rem',
  backgroundColor: 'transparent',
  border: 'none',
  color: theme.secondary,
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '.7rem',
  letterSpacing: '1px',
  transition: 'opacity .3s ease-in-out',
  outlineColor: theme.secondary,

  '& > svg': {
    width: '1rem',
    marginLeft: '.5rem',
  },

  '& > span': {
    paddingBottom: '.1rem'
  },

  '&:hover': {
    opacity: .6
  }
}));

export const CVLink = styled.a(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  width: '80px',
  height: '80px',
  textAlign: 'center',
  color: 'white',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: '.7rem',
  cursor: 'pointer',
  zIndex: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textDecoration: 'none',

  '&:after': {
    position: 'fixed',
    zIndex: -1,
    right: '-310px',
    bottom: '-710px',
    content: `''`,
    width: '200px',
    height: '1000px',
    transform: 'translateZ(0) rotate(50deg)',
    transformOrigin: '67px 103px 0',
    transition: 'all .3s',
    background: theme.secondary,
  },

  '&:hover': {
    [CVLink.SVG]: {
      transform: 'scale(1)',
    }
  }
}));

CVLink.SVG = styled.svg(({ theme }) => ({
  width: '20px',
  height: '20px',
  overflow: 'hidden',
  transform: 'scale(0)',
  transition: 'transform .3s ease-in-out',
  zIndex: 1001,
}));
