import React from "react";
import * as S from './styles';

const Pattern = () => {
  return(
    <S.Pattern>
      <S.Pattern.Column />
      <S.Pattern.Column />
      <S.Pattern.Column />
      <S.Pattern.Column />
      <S.Pattern.Column />
      <S.Pattern.Column />
      <S.Pattern.Column />
      <S.Pattern.Column />
      <S.Pattern.Column />
      <S.Pattern.Column />
      <S.Pattern.Column />
      <S.Pattern.Column />
    </S.Pattern>
  );
};

export default Pattern;
