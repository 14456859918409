import styled from 'styled-components';
import mediaMin, { breakpoints } from '../../styles/mediaQueries';

const Page = styled.div(({ theme }) => (
  {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowX: 'hidden',
    minHeight: '100%',
    paddingBottom: '3rem',

    '*::-moz-selection': { background: theme.secondary, color: theme.white },
    '*::selection': { background: theme.secondary, color: theme.white },

    [mediaMin(breakpoints.md)]: {
      height: '100vh',
    }
  }
));

const Container = styled.main(
  {
    position: 'relative',
    display: 'flex',
    flex: '1',
    zIndex: 1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: '80rem',
    margin: '0 auto',
    padding: '0 1rem',
    paddingTop: '100px',

    [mediaMin(breakpoints.md)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    }
  }
);

const Column = styled.section(
  {
    flex: '0 0 100%',
    paddingTop: '3rem',

    [mediaMin(breakpoints.md)]: {
      paddingTop: '6rem',
      flex: '0 0 45%',
    }
  }
);

const ColumnLeft = styled(Column)(({ theme }) => ({
  position: 'relative',
  color: theme.primary,
}));

const ColumnRight = styled(Column)(({ theme }) => ({
  color: theme.primary,

  [mediaMin(breakpoints.md)]: {
    paddingTop: '10rem',
  }
}));

const FlexContainer = styled.div( 
  {
    display: 'flex',
    flexFlow: 'row wrap',
    margin: '0',

    [mediaMin(breakpoints.md)]: {
      margin: '1rem 0',
    }
  }
);

export {
  Container,
  Page,
  ColumnLeft,
  ColumnRight,
  FlexContainer
};
