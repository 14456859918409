import styled from 'styled-components';
import mediaMin, { breakpoints } from '../../styles/mediaQueries';

export const H1 = styled.h1(({ theme }) => (
  {
    margin: 0,
    paddingBottom: '.5rem',
    fontSize: '2rem',
    color: 'inherit',

    [mediaMin(breakpoints.md)]: {
      fontSize: '3rem',
      paddingBottom: '1rem',
    },

    [mediaMin(breakpoints.lg)]: {
      fontSize: '4rem',
      paddingBottom: '1.5rem',
    }
  }
));

export const H2 = styled.h2(({ theme }) => (
  {
    margin: 0,
    paddingTop: '.3rem',
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.3,
    color: 'inherit',
  }
));

export const Heading = styled.h2(({ theme }) => (
  {
    margin: 0,
    paddingTop: '1rem',
    paddingBottom: '1rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.5,
    color: 'inherit',
  }
));

export const H3 = styled.h3(({ theme }) => (
  {
    margin: 0,
    fontSize: '.75rem',
    color: theme.primary,

    [mediaMin(breakpoints.lg)]: {
      fontSize: '.85rem',
    }
  }
));

export const H4 = styled.h4(({ theme }) => (
  {
    margin: '1.5rem 0 .5rem',
    fontSize: '.9rem',
    color: theme.secondary,
  }
));

export const P = styled.p(({ theme }) => (
  {
    fontFamily: 'Helvetica, Arial, sans-serif',
    margin: 0,
    padding: '1rem 0',
    fontSize: '..9rem',
    color: 'inherit',
    lineHeight: 1.5,

    '& + &': {
      paddingTop: '0'
    },

    [mediaMin(breakpoints.md)]: {
      fontSize: '1rem',
    }
  }
));

export const Tag = styled.span(({ theme }) => (
  {
    margin: '0',
    marginRight: '.5rem',
    marginBottom: '.5rem',
    padding: '.3rem .6rem',
    backgroundColor: theme.secondary,
    fontSize: '.65rem',
    fontWeight: '500',
    color: theme.white,
    letterSpacing: '.4px',
    borderRadius: '3rem',
    whiteSpace: 'nowrap',
  }
));

export const TagColor = styled(Tag)(({ theme }) => (
  {
    padding: '0',
    fontSize: '.9rem',
    fontWeight: '600',
    color: theme.secondary,
    backgroundColor: 'transparent',
  }
));

export const TagLarge = styled.p(({ theme }) => (
  {
    margin: 0,
    marginLeft: '-1px',
    marginTop: '-1px',
    padding: '.5rem',
    fontSize: '.7rem',
    color: theme.white,
    fontWeight: 600,
    letterSpacing: '.5px',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.white}`,
  }
));

export const Link = styled.a(({ theme }) => (
  {
   opacity: .4,
   cursor: 'pointer',
   transition: 'all .3s ease-in-out',
   textDecoration: 'none',
   paddingTop: '0.4rem',
   color: theme.text,

   '&:hover': {
      color: theme.secondary,
      opacity: 1,
   }
  }
));
