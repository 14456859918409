import React from 'react';
import * as S from './styles';
import PDF from '../../files/ks_cv.pdf';

export const PreviewAction = ({ children, action }) => (
  <S.IconButton onClick={action}>
    <span>{children}</span>

    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 92 92" enableBackground="new 0 0 92 92">
    <path fill='currentColor' id="XMLID_1239_" d="M91.3,43.8C90.6,42.8,74.4,19,46,19C17.6,19,1.4,42.8,0.7,43.8c-0.9,1.3-0.9,3.1,0,4.5
      C1.4,49.2,17.6,73,46,73c28.4,0,44.6-23.8,45.3-24.8C92.2,46.9,92.2,45.1,91.3,43.8z M46,65C26.7,65,13.5,51.4,9,46
      c4.5-5.5,17.6-19,37-19c19.3,0,32.5,13.6,37,19C78.4,51.5,65.3,65,46,65z M48.3,29.6c-4.4-0.6-8.7,0.5-12.3,3.2c0,0,0,0,0,0
      c-7.3,5.5-8.8,15.9-3.3,23.2c2.7,3.6,6.5,5.8,10.9,6.5c0.8,0.1,1.6,0.2,2.3,0.2c3.6,0,7-1.2,9.9-3.3c7.3-5.5,8.8-15.9,3.3-23.2
      C56.6,32.5,52.7,30.2,48.3,29.6z M52.3,54.5c-2.2,1.7-5,2.4-7.8,2c-2.8-0.4-5.3-1.9-7-4.1C34.1,47.7,35,41,39.7,37.5
      c2.2-1.7,5-2.4,7.8-2c2.8,0.4,5.3,1.9,7,4.1C57.9,44.3,57,51,52.3,54.5z M51.9,40c0.8,0.7,1.2,1.8,1.2,2.8c0,1-0.4,2.1-1.2,2.8
      c-0.7,0.7-1.8,1.2-2.8,1.2c-1.1,0-2.1-0.4-2.8-1.2c-0.8-0.8-1.2-1.8-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8c0.7-0.8,1.8-1.2,2.8-1.2
      C50.2,38.9,51.2,39.3,51.9,40z"/>
    </svg>
  </S.IconButton>
);

export const WebsiteLink = ({ children, href }) => (
  <S.IconLink href={href} target='_blank'>
    <span>{children}</span>

  <svg version="1.1" width="20px" height="20px" viewBox="0 0 92 92" enableBackground="new 0 0 92 92">
    <path fill='currentColor' id="XMLID_1504_" d="M90.8,63.9L67,40.1c-1.6-1.6-4.1-1.6-5.7,0l-7.8,7.8l-9.4-9.4l7.8-7.8c1.6-1.6,1.6-4.1,0-5.7L28.1,1.2
      c-1.6-1.6-4.1-1.6-5.7,0L1.2,22.5C0.4,23.2,0,24.2,0,25.3c0,1.1,0.4,2.1,1.2,2.8L25,51.9c0.8,0.8,1.8,1.2,2.8,1.2c1,0,2-0.4,2.8-1.2
      l7.8-7.8l9.4,9.4l-7.8,7.8c-1.6,1.6-1.6,4.1,0,5.7l23.8,23.8c0.8,0.8,1.8,1.2,2.8,1.2c1.1,0,2.1-0.4,2.8-1.2l21.3-21.3
      C92.4,68,92.4,65.4,90.8,63.9z M27.8,43.4L9.7,25.3L25.3,9.7l18.1,18.1l-5,5l-3-3c-1.6-1.6-4.1-1.6-5.7,0c-1.6,1.6-1.6,4.1,0,5.7
      l3,3L27.8,43.4z M66.7,82.3L48.6,64.2l5-5l3,3c0.8,0.8,1.8,1.2,2.8,1.2c1,0,2-0.4,2.8-1.2c1.6-1.6,1.6-4.1,0-5.7l-3-3l5-5l18.1,18.1
      L66.7,82.3z"/>
  </svg>
  </S.IconLink>
);

export const CVLink = ({ children, href }) => (
  <S.CVLink href={PDF} download="CV_Kinga_Sieminiak">
    <S.CVLink.SVG viewBox="0 0 29.978 29.978">
      <g>
        <path d="M25.462,19.105v6.848H4.515v-6.848H0.489v8.861c0,1.111,0.9,2.012,2.016,2.012h24.967c1.115,0,2.016-0.9,2.016-2.012   v-8.861H25.462z" fill="#FFFFFF"/>
        <path d="M14.62,18.426l-5.764-6.965c0,0-0.877-0.828,0.074-0.828s3.248,0,3.248,0s0-0.557,0-1.416c0-2.449,0-6.906,0-8.723   c0,0-0.129-0.494,0.615-0.494c0.75,0,4.035,0,4.572,0c0.536,0,0.524,0.416,0.524,0.416c0,1.762,0,6.373,0,8.742   c0,0.768,0,1.266,0,1.266s1.842,0,2.998,0c1.154,0,0.285,0.867,0.285,0.867s-4.904,6.51-5.588,7.193   C15.092,18.979,14.62,18.426,14.62,18.426z" fill="#FFFFFF"/>
      </g>
    </S.CVLink.SVG>

    <p>cv pdf</p>
  </S.CVLink>
);