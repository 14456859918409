import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import favicon16 from "../../images/favicons/favicon-16x16.png";
import favicon32 from "../../images/favicons/favicon-32x32.png";
import favicon96 from "../../images/favicons/favicon-96x96.png";
import faviconAndroid from "../../images/favicons/android-icon-144x144.png";
import faviconIOS from "../../images/favicons/apple-icon-144x144.png";
import SEOImage from '../../images/kingaSieminiak.png';

const SEO = ({ title, description, image, pathname, article, keywords }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          defaultDescription,
          siteUrl,
          defaultImage,
          defaultKeywords,
          favicons
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`,
        keywords: keywords || defaultKeywords,
      }

      return (
        <Fragment>
          <Helmet title={seo.title}>
            <meta name="description" content={seo.description} />
            <meta name="image" content={SEOImage} />
            <meta name="robots" content="index,follow" />

            {seo.keywords && <meta name="keywords" content={seo.kaywords}></meta>}
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={seo.title}></meta>}
            {seo.description && <meta property="og:description" content={seo.description}></meta>}
            <meta property="og:image" content={SEOImage}></meta>

            {(article ? true : null ) && <meta property="og:type" content="article" />}

            <link rel="apple-touch-icon" sizes="114x114" href={faviconIOS} />
            <link rel="icon" type="image/png" sizes="144x144" href={faviconAndroid} />
            <link rel="icon" type="image/png" sizes="32x32" href={favicon16} />
            <link rel="icon" type="image/png" sizes="96x96" href={favicon96} />
            <link rel="icon" type="image/png" sizes="16x16" href={favicon32} />
          </Helmet>
        </Fragment>
      )
    }}
  />
)

export default SEO

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  keywords: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image,
        defaultKeywords: keywords,
      }
    }
  }
`;
