import styled from 'styled-components';
import mediaMin, { breakpoints } from '../../styles/mediaQueries';

export const Pattern = styled.div(({ theme }) => (
  {
    position: 'fixed',
    zIndex: '-1',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    maxWidth: '80rem',
    margin: '0 auto',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
    gridGap: '1rem',
    padding: '0 1rem',
    pointerEvents: 'none',

    [mediaMin(breakpoints.md)]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
      gridGap: '2rem',
    }
  }
));

Pattern.Column = styled.div(
  {
    height: '100%',
    border: '1px solid rgba(0,0,0,.05)',

    '&:nth-child(12), &:nth-child(11), &:nth-child(10), &:nth-child(9), &:nth-child(8), &:nth-child(7)': {
      display: 'none'
    },

    [mediaMin(breakpoints.md)]: {
      '&:nth-child(12), &:nth-child(11), &:nth-child(10), &:nth-child(9), &:nth-child(8), &:nth-child(7)': {
        display: 'block'
      }
    }
  }
);
