import styled from 'styled-components';
import mediaMin, { breakpoints } from '../../styles/mediaQueries';

export const Burger = styled.button(
  {
    position: 'relative',
    width: '70px',
    height: '63px',
    padding: '15px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    transition: 'opacity .3s ease-in-out',
    zIndex: 2,

    '&:hover': { opacity: .8 },

    [mediaMin(breakpoints.md)]: {
      display: 'none',
    }
  },
  ({ theme, color, isActive }) => ({
    outlineColor: theme.primary,
    [`& > span`]: { backgroundColor: theme[color] },
    margin: isActive ? '0 -1px 0' : 0,
  })
);

Burger.Item = styled.span(
  {
    position: 'absolute',
    left: 'calc((100% - 40px) / 2)',
    width: '40px',
    height: '6px',
    transition: 'all .25s ease-in-out .2s',

    '&:first-child': { top: '15px' },
    '&:nth-child(2)': { top: '27px' },
    '&:nth-child(3)': { top: '27px' },
    '&:last-child': { top: '38px' },
  },
  ({ theme, isActive }) => (isActive && {
    '&:first-child': { top: '27px', width: '0%', left: '50%' },
    '&:nth-child(2)': { transform: 'rotate(45deg)' },
    '&:nth-child(3)': { transform: 'rotate(-45deg)' },
    '&:last-child': { top: '27px', width: '0%', left: '50%' },
    }
  ),
);

