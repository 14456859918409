import React from 'react';
import * as S from './styles';

const BurgerButton = ({ action, isActive, color }) => {
  return (
    <S.Burger onClick={action} isActive={isActive} color={color}>
      <S.Burger.Item isActive={isActive} />
      <S.Burger.Item isActive={isActive} />
      <S.Burger.Item isActive={isActive} />
      <S.Burger.Item isActive={isActive} />
    </S.Burger>
  );
}

export default BurgerButton;
