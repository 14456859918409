import React from 'react';
import styled from 'styled-components';
import Nav from '../nav';
import { NavManager, NavContext } from '../../context/NavManager';

const Header = ({ action, nav }) => (
  <NavManager>
    <NavContext.Consumer>
      {context => (
        <Header.Content>
          <Nav navItems={nav} />
        </Header.Content>
      )}
    </NavContext.Consumer>
  </NavManager>
);

export default Header;

Header.Content = styled.header(({ theme }) => (
  {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 10,
    width: '100%',
    padding: '1rem 0',
    color: 'white',
  }
));

Header.Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 90rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

Header.Button = styled.a(({ theme }) => (
  {
    position: 'relative',
    width: '70px',
    height: '100%',
    border: 'none',
    borderLeft: '1px solid white',
    backgroundColor: theme.secondary,
    color: 'white',
    fontWeight: 700,
    outline: theme.secondary,
    transition: 'color .3s ease-in-out .1s',
    textAlign: 'center',
    lineHeight: '60px',
    fontSize: '0.65rem',
    textDecoration: 'none',

    '&:hover': {
      color: 'transparent',

      '> svg': {
        transform: 'scale(1)',
        color: 'white',
      }
    },

    '&:active': {
      boxShadow: 'inset 0 0 20px rgba(0,0,0,.1)'
    }
  }
));
