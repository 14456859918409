import styled from 'styled-components';
import mediaMin, { breakpoints, mediaMax } from '../../styles/mediaQueries';
import { Link } from '@reach/router';

export const Nav = styled.nav({
  [mediaMin(breakpoints.md)]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    fontSize: '2rem',
    padding: '2rem 0',
  }
});

Nav.Container = styled.div(({ theme }) => ({
  width: '100%',
  maxWidth: '80rem',
  margin: '0 auto',

  [mediaMin(breakpoints.md)]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    padding: '0 1rem',
    fontSize: '2rem',
  }
}));

Nav.Logo = styled.div(
  {
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItem: 'center',
    width: '140px',
    height: '85px',
    padding: '0 1rem',
    fontWeight: '600',

    '&:after': {
      position: 'fixed',
      left: '190px',
      top: '-350px',
      content: `''`,
      width: '200px',
      height: '1000px',
      transform: 'translateZ(0) rotate(50deg)',
      transformOrigin: '67px 103px 0',
      transition: 'all .3s',
      zIndex: '-1',
    },

    'p': {
      margin: 0,
    },

    [mediaMin(breakpoints.md)]: {
      width: '220px',
      height: '140px',
      padding: '0 2rem',

      '&:after': {
        top: '-270px',
      },
    }
  },
  ({ theme }) => ({
    color: `${theme.primary}`,
    fontSize: '1.1rem',

    '&:after': {
      backgroundColor: `${theme.secondary}`,
    },
  }),
  ({ isActive }) => isActive && {
    [mediaMax(breakpoints.md)]: {
      '&:after': {
        transform: 'translateZ(0) rotate(50deg) scale(4.5)',
      },
    }
  },
);

Nav.Title = styled.div({
  display: 'none',

  [mediaMin(breakpoints.md)]: {
    display: 'block',
  }
});

Nav.List = styled.ul(
  {
    position: 'fixed',
    left: 0,
    width: '100%',
    transition: 'all .3s ease',
    zIndex: 1,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    padding: '0',
    lineHeight: 1.5,
    listStyle: 'none',
    fontSize: '1.5rem',

    [mediaMax(breakpoints.md)]: {
      top: '80px',
      transform: 'translateX(-100%)',
    },

    [mediaMin(breakpoints.md)]: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      fontSize: '1.3rem',
    }
  },
  ({ isActive }) => isActive && {
    [mediaMax(breakpoints.md)]: {
      transform: 'translateX(0)',

      [Nav.Link]: {
        transform: 'scale(1)',
      }
    },
  },
);

Nav.Link = styled(Link)(({ theme, active, open }) => ({
  position: 'relative',
  display: 'inline-block',
  padding: '0',
  textDecoration: 'none',
  color: active ? 'white' : theme.primary,
  fontWeight: 600,
  transition: 'color .3s ease-in-out, transform .1s ease-in-out 0.2s',
  borderBottom: `2px solid ${active ? theme.secondary : 'transparent'}`,
  overflow: 'hidden',

  '&:hover': {
    color: theme.secondary
  },

  [mediaMin(breakpoints.md)]: {
    margin: 0,
    marginLeft: '1rem',
    color: active ? theme.secondary : theme.primary,
  },

  [mediaMax(breakpoints.md)]: {
    margin: '.5rem',
    transform: 'scale(0)',
    borderBottom: `2px solid ${active ? theme.white : 'transparent'}`,

    '&:hover': {
      color: theme.white
    },
  },
}))
