import React, { Component, createContext } from 'react';

const NavContext = createContext();

class NavManager extends Component {
  state = {
    isNavOpen: false
  }

  toggleNav = () => {
    this.setState(state => state.isNavOpen = !state.isNavOpen);
  }

  render() {
    const context = {
      state: this.state,
      toggleNav: this.toggleNav
    }

    return (
      <NavContext.Provider value={context}>
        {this.props.children}
      </NavContext.Provider>
    );
  }
}

export { NavContext, NavManager }
