import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body, html {
    font-family: Didot, Arial, sans-serif;
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    background-color: white;
    font-size: 100%; 
  }

  #___gatsby,
  #___gatsby > div {
    height: 100vh;
  }

  button,
  a {
    touch-action: manipulation;
  }

  * {
    box-sizing: border-box;
  }
`

export default GlobalStyle;
