const theme = {
  main: {
    primary: '#044376',
    secondary: '#f46359',
    background: '#ededed',
    text: 'white',
    white: 'white',
    difference: '#f46359'
  },
  projects: {
    primary: '#044376',
    secondary: '#f46359',
    background: '#ededed',
    text: '#044376',
    white: '#FFFFFF',
  },
  about: {
    primary: '#044376',
    secondary: '#f46359',
    background: '#ededed',
    text: '#044376',
    white: '#FFFFFF',
  },
  experience: {
    primary: '#044376',
    secondary: '#f46359',
    background: '#ededed',
    text: '#044376',
    white: '#FFFFFF',
  }
}

export default theme;
